import React, { useState, useEffect } from "react"
import Title from "../../../layout/title"
import TextInput from "../../../base/forms/textInput"
import Label from "../../../base/forms/label"
import Button from "../../../base/buttons/regular"
import useUser from "../../../../utils/hooks/useUser"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../../../utils/hooks/useFormatMessage"
import notifications from "../../../../utils/notifications"
import ModalContainer from "../../../base/modals/modalContainer"
import DateInput from "../../../base/forms/dateInput"
import { Invitation } from "src/api/types"
import usePatchInvitation from "../../../../utils/hooks/usePatchInvitation"

const messages = defineMessages({
  editInvitationTitle: "Edit invitation",
  testLanguageSelectInputLabel: "Language to be tested:",
  startTimeLabel: "Start time:",
  expirationTimeLabel: "Expiration time:",
  startedTimeLabel: "Starting time:",
  maxEvaluationsNumberInputLabel: "Maxium number of evaluations:",
  invitationNameInputLabel: "Name",
  maxEvaluationsNumberInputPlaceholder: "Max. Evaluations",
  submitButton: "Submit",
  createInvitationSuccessNotificationTitle: "You have created a new invitation!",
  createInvitationSuccessNotificationMessage: "You can now use the related URL to send out to the participants.",
  createInvitationErrorNotificationTitle: "Oops! Something went wrong.",
})

type Props = {
  visible?: boolean
  invitation: Invitation
  onClose?: () => void
}

const UpdateInvitationModal: React.FC<Props> = props => {
  const { visible, invitation, onClose } = props

  const formatMessage = useFormatMessage()

  const { patchInvitation } = usePatchInvitation()

  const [name, setName] = useState<string>(invitation.invitationname)
  const [maxEvals, setMaxEvals] = useState<string>(`${invitation.maxevaluations}`)
  const [startDate, setStartDate] = useState<string | null>(invitation.startstimestamp)
  const [expiryDate, setExpiryDate] = useState<string>(invitation.expirestimestamp)

  const [companyUUID, setCompanyUUID] = useState("")
  const [userUUID, setUserUUID] = useState("")
  const user = useUser()
  useEffect(() => {
    if (user && user.company && user.user) {
      setCompanyUUID(user.company.uuid)
      setUserUUID(user.user.uuid)
    }
  }, [user])

  const handleSubmit = async () => {
    try {
      await patchInvitation({
        ...invitation,
        invitationname: name,
        maxevaluations: parseInt(maxEvals, 10),
        startstimestamp: startDate,
        expirestimestamp: expiryDate,
        companyuuid: companyUUID,
        hruuid: userUUID,
      })
      notifications.success(
        formatMessage(messages.createInvitationSuccessNotificationTitle),
        formatMessage(messages.createInvitationSuccessNotificationMessage)
      )
      if (onClose) {
        onClose()
      }
      window?.location?.reload()
    } catch (error) {
      //@ts-ignore
      notifications.error(formatMessage(messages.createInvitationErrorNotificationTitle), error.message, error.details)
    }
  }
  return (
    <ModalContainer className="w-96" visible={visible} onClose={onClose}>
      <div className="px-10 pb-12">
        <div className="flex flex-col justify-between -mt-4">
          <Title>{formatMessage(messages.editInvitationTitle)}</Title>
          <Label className="mb-4" name={formatMessage(messages.invitationNameInputLabel)}>
            <TextInput
              type="string"
              placeholder={formatMessage(messages.invitationNameInputLabel)}
              name="invitationname"
              value={name}
              onChange={(event: any) => {
                setName(event.target.value)
              }}
            />
          </Label>
          <Label className="mt-4 mb-4" name={formatMessage(messages.maxEvaluationsNumberInputLabel)}>
            <TextInput
              type="number"
              placeholder={formatMessage(messages.maxEvaluationsNumberInputLabel)}
              name="maxevaluations"
              value={maxEvals}
              onChange={(event: any) => {
                setMaxEvals(event.target.value)
              }}
            />
          </Label>
          <Label className="mb-4" name={formatMessage(messages.startedTimeLabel)}>
            <DateInput name="startedtimestamp" value={startDate ?? new Date().toISOString()} onChange={setStartDate} />
          </Label>
          <Label className="mb-4" name={formatMessage(messages.expirationTimeLabel)}>
            <DateInput name="expirestimestamp" value={expiryDate} onChange={setExpiryDate} />
          </Label>
          <Button primary className="mt-4" onClick={handleSubmit} loading={false}>
            {formatMessage(messages.submitButton)}
          </Button>
        </div>
      </div>
    </ModalContainer>
  )
}

export default UpdateInvitationModal
