import _ from "lodash"
import React from "react"
import { defineMessages } from "gatsby-plugin-intl"
import { getLanguageCode } from "../../../../utils/language"
import { EvaluationType, Invitation } from "src/api/types"
import Action from "../components/Actions"
import { format, isPast, parse } from "date-fns"
import { getLevelEvaluationTypeNameForType } from "src/utils/evaluation-type-utils"
import { useThemeColors } from "src/utils/hooks/useThemeColors"

const messages = defineMessages({
  urlHeader: "URL",
  languageHeader: "Language",
  invitationName: "Name",
  maxEvaluationsHeader: "Max sessions",
  testsUsed: "Tests used",
  usedEvaluationsHeader: "Used # sessions",
  unusedEvaluationsHeader: "Unused # sessions",
  availabilityHeader: "Available",
  startsHeader: "Starts",
  expiresHeader: "Expires",
  actionsHeader: "Actions",
})

type Attribute = string | null

type Header = {
  id: string // the ID of the column
  text?: string // the name of displayed on the header
  width: string // the width as a TW class
  mobileWidth?: string // the width on mobile as a TW class
  sortableIcon?: boolean // whether or not the column is sortable
  noTooltip?: boolean // whether or not a tooltip should be shown
  notClickable?: boolean // whether or not this header is clickable
  extractAttribute?: (data: Invitation) => Attribute // extracts the attribute from the object
  render: React.FC<{ data: Invitation; attribute: Attribute }> // functional component how to render the child
}

const headers: Header[] = [
  {
    id: "invitationname",
    text: messages.invitationName,
    width: "w-3/12",
    noTooltip: true,
    extractAttribute: data => data.invitationname,
    render: ({ data, attribute }) => {
      const evalType = data?.letypecode ?? EvaluationType.STANDARD
      const showBadge = evalType !== EvaluationType.STANDARD
      const badgeContent = _.startCase(getLevelEvaluationTypeNameForType(evalType))
      const { regular } = useThemeColors()
      return (
        <div className="flex flex-row justify-center items-center">
          <span className="text-sm font-medium text-gray-600">{attribute}</span>
          {showBadge && <span className={`text-xs font-medium mx-1 text-${regular}`}>({badgeContent})</span>}
        </div>
      )
    },
  },
  {
    id: "evaluatedlanguage",
    text: messages.languageHeader,
    width: "w-2/12",
    noTooltip: true,
    extractAttribute: data => data.evaluatedlanguage,
    render: ({ attribute }) => {
      return (
        <div className="flex flex-row justify-center">
          <span className="text-sm w-6 font-semibold text-gray-700">{getLanguageCode(attribute?.toUpperCase())}</span>
        </div>
      )
    },
  },
  {
    id: "usage",
    text: messages.testsUsed,
    width: "w-2/12",
    mobileWidth: "w-3/12",
    noTooltip: true,
    extractAttribute: data => `${data.maxevaluations - data.unusedevaluations}/${data.maxevaluations}`,
    render: ({ data, attribute }) => {
      const max = data?.maxevaluations ?? 0
      const used = Math.max(0, max - data?.unusedevaluations ?? 0)
      const percentage = used / max
      const color = percentage > 0.999 ? "text-red-600" : percentage >= 0.75 ? "text-orange-600" : "text-green-600"
      return <div className={`font-mono font-medium text-xs ${color}`}>{attribute}</div>
    },
  },
  {
    id: "startstimestamp",
    text: messages.startsHeader,
    width: "w-2/12",
    noTooltip: true,
    extractAttribute: data => {
      return format(new Date(data.startstimestamp ?? new Date()), "PP")
    },
    render: ({ attribute }) => <span className="text-gray-600 text-xs">{attribute}</span>,
  },
  {
    id: "expirestimestamp",
    text: messages.expiresHeader,
    width: "w-2/12",
    noTooltip: true,
    extractAttribute: data => format(new Date(data.expirestimestamp ?? new Date()), "PP"),
    render: ({ attribute }) => {
      const isExpired = isPast(parse(attribute ?? "", "PP", new Date()))
      return <span className={`${isExpired ? "text-red-600" : "text-gray-600"} text`}>{attribute}</span>
    },
  },
  {
    id: "token",
    text: messages.actionsHeader,
    width: "w-1/12",
    noTooltip: true,
    sortableIcon: false,
    extractAttribute: data => data.uuid,
    render: ({ data }) => <Action row={data} />,
  },
]

export default headers
