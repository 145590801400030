import { useState } from "react"
import { createInvitation } from "src/api"
import { v4 as uuid } from "uuid"
import { useDispatch } from "react-redux"
import { fetchInvitations } from "../../store/invitations/actions"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { getAPI_CALL_STARTED } from "../gaConstants"
/*
Note invitation put object:
{
  "token": "redfin-bepile-hoop",
  "maxevaluations": 50,
  "totalevaluations": 4,
  "unusedevaluations": 46,
  "startstimestamp": "YYYY-MM-DD",
  "expirestimestamp": "YYYY-MM-DD",
  "companyuuid":"dba60e20-cae2-4f6b-8062-bc49a685d3fa",
  "evaluatedlanguage": "en"
}
*/
export default () => {
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const execute = async invitation => {
    try {
      trackCustomEvent(getAPI_CALL_STARTED("CREATE_INVITATION"))

      setLoading(true)
      setError(undefined)
      const invitationUUID = uuid()
      const data = await createInvitation(invitationUUID, invitation)
      dispatch(fetchInvitations(data.company.uuid))
      return data
    } catch (error) {
      Sentry.captureException(error)

      setError(error.message)
      throw error
    } finally {
      setLoading(false)
    }
  }

  return { error, loading, createInvitation: execute }
}
