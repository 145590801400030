import React, { useState, useEffect } from "react"
import headers from "./utils/headers"
import PaginatedTable from "../../base/paginated-table"
import CreateInvitationModal from "./components/createInvitationModal"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../../utils/hooks/useFormatMessage"
import useUser from "../../../utils/hooks/useUser"
import { useSelector } from "react-redux"
import { fetchInvitations } from "../../../store/invitations/actions"
import { invitationsSelector } from "../../../store/invitations/selectors"
import Button from "../../base/buttons/regular"
import { useDispatch } from "react-redux"
import _ from "lodash"
import { isPast, parseISO } from "date-fns"

const messages = defineMessages({
  createInvitationButton: "Add URL",
  showExpiredInvitations: "Show expired invitation links",
})

const Invitations = ({ className }) => {
  const [invitation, setInvitation] = useState(false)

  const dispatch = useDispatch()
  const formatMessage = useFormatMessage()
  const user = useUser()
  let companyUUID = _.get(user, "company.uuid")

  useEffect(() => {
    companyUUID = _.get(user, "company.uuid")
    if (!!companyUUID) {
      dispatch(fetchInvitations(companyUUID))
    }
  }, [user])

  const [showExpired, setShowExpired] = useState(false)

  const { result } = useSelector(invitationsSelector(companyUUID))

  const results = result?.filter(entry => showExpired || !isPast(parseISO(entry.expirestimestamp)))

  return (
    <>
      <div className={`flex flex-col w-full ${className}`}>
        <PaginatedTable data={results} headers={headers} />
        <div className="flex flex-row items-center justify-end">
          <div className="relative flex items-start mr-4">
            <div className="flex items-center h-5">
              <input
                id="show-expired"
                aria-describedby="show-expired-description"
                name="show-expired"
                type="checkbox"
                onChange={() => setShowExpired(!showExpired)}
                className="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded"
              />
            </div>
            <div className="ml-2 text-sm">
              <label htmlFor="show-expired" className="font-base text-gray-700">
                {formatMessage(messages.showExpiredInvitations)}
              </label>
            </div>
          </div>
          <Button primary onClick={() => setInvitation(true)}>
            {formatMessage(messages.createInvitationButton)}
          </Button>
        </div>
      </div>
      <CreateInvitationModal visible={invitation} onClose={() => setInvitation(false)} />
    </>
  )
}

export default Invitations
