import { getInvitationList } from "src/api"
import { onLogout } from "../account/actions"

export const setInvitations = (companyUUID, invitations) => ({
  type: "invitations/SET_INVITATIONS",
  companyUUID,
  payload: invitations,
})

export const setLoading = (companyUUID, loading) => ({
  type: "invitations/SET_LOADING",
  companyUUID,
  payload: loading,
})

export const setError = (companyUUID, error) => ({
  type: "invitations/SET_ERROR",
  companyUUID,
  payload: error,
})

export const fetchInvitations = companyUUID => async dispatch => {
  dispatch(setLoading(companyUUID, true))
  try {
    const invitations = await getInvitationList(companyUUID)
    dispatch(setInvitations(companyUUID, invitations))
  } catch (error) {
    dispatch(setError(companyUUID, error))
    if ((error.code = "ForbiddenAccess" && error.message === "No valid authentication")) {
      dispatch(onLogout())
    }
  } finally {
    dispatch(setLoading(companyUUID, false))
  }
}
