import { useState } from "react"
import { patchInvitation } from "src/api"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { getAPI_CALL_STARTED } from "../gaConstants"
export default () => {
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(false)

  const execute = async invitation => {
    try {
      trackCustomEvent(getAPI_CALL_STARTED("UPDATE_INVITATION"))

      setLoading(true)
      setError(undefined)
      const data = await patchInvitation(invitation.uuid, invitation)

      return data
    } catch (error) {
      Sentry.captureException(error)

      setError(error.message)
      throw error
    } finally {
      setLoading(false)
    }
  }

  return { error, loading, patchInvitation: execute }
}
