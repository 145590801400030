import _ from "lodash"
import React, { useState, useEffect } from "react"
import moment from "moment"
import Title from "../../../layout/title"
import Select from "../../../base/forms/select"
import TextInput from "../../../base/forms/textInput"
import Label from "../../../base/forms/label"
import Button from "../../../base/buttons/regular"
import { languageOptionsTest } from "./utils/languageOptions"
import useCreateInvitation from "../../../../utils/hooks/useCreateInvitation"
import useUser from "../../../../utils/hooks/useUser"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../../../utils/hooks/useFormatMessage"
import notifications from "../../../../utils/notifications"
import ModalContainer from "../../../base/modals/modalContainer"
import DateInput from "../../../base/forms/dateInput"
import { useCurrentCompany } from "src/utils/hooks/useCurrentCompany"
import {
  EvaluationType,
  getBaseEvaluationTypeForCompany,
  getLevelEvaluationTypeNameForType,
  getLevelEvaluationTypesForCompany,
} from "src/utils/evaluation-type-utils"

const messages = defineMessages({
  title: "Create new invitation",
  testLanguageSelectInputLabel: "Language to be tested:",
  startTimeLabel: "Start time:",
  expirationTimeLabel: "Expiration time:",
  maxEvaluationsNumberInputLabel: "Maxium number of evaluations:",
  invitationNameInputLabel: "Name",
  maxEvaluationsNumberInputPlaceholder: "Max. Evaluations",
  evaluationTypeSelectInputLabel: "Type of evaluation",
  submitButton: "Submit",
  createInvitationSuccessNotificationTitle: "You have created a new invitation!",
  createInvitationSuccessNotificationMessage: "You can now use the related URL to send out to the participants.",
  createInvitationErrorNotificationTitle: "Oops! Something went wrong.",
})

const CreateInvitationModal = ({ visible, onClose }) => {
  const [invitation, setInvitation] = useState({
    evaluatedlanguage: "fr",
    inivtationname: "",
    maxevaluations: 100,
    startstimestamp: moment().format("YYYY-MM-DD"),
    expirestimestamp: moment()
      .add(30, "days")
      .format("YYYY-MM-DD"),
  })
  const [companyUUID, setCompanyUUID] = useState("")
  const [userUUID, setUserUUID] = useState("")
  const user = useUser()

  // check if they are allowed to create multiple types
  const company = useCurrentCompany()
  const [evalType, setEvaltype] = useState(EvaluationType.STANDARD)
  const evalTypes = getLevelEvaluationTypesForCompany(company)
  const askForEvalType = evalTypes.length > 1
  const evalTypeOptions = evalTypes.map(evalType => ({
    name: _.startCase(getLevelEvaluationTypeNameForType(evalType)),
    value: evalType,
  }))

  useEffect(() => {
    if (user && user.company && user.user) {
      setCompanyUUID(user.company.uuid)
      setUserUUID(user.user.uuid)
    }
  }, [user])

  const formatMessage = useFormatMessage()

  const { loading, createInvitation } = useCreateInvitation()

  const handleChange = e => {
    setInvitation({ ...invitation, [e.target.name]: e.target.value })
  }

  const handleDateChange = (date, key) => {
    setInvitation({ ...invitation, [key]: date })
  }

  const handleSubmit = async () => {
    try {
      await createInvitation({
        ...invitation,
        companyuuid: companyUUID,
        hruuid: userUUID,
        letypecode: askForEvalType ? evalType : getBaseEvaluationTypeForCompany(company),
      })
      notifications.success(
        formatMessage(messages.createInvitationSuccessNotificationTitle),
        formatMessage(messages.createInvitationSuccessNotificationMessage)
      )
      onClose()
    } catch (error) {
      notifications.error(formatMessage(messages.createInvitationErrorNotificationTitle), error.message, error.details)
    }
  }
  return (
    <ModalContainer className="w-96" visible={visible} onClose={onClose}>
      <div className="px-10 pb-12">
        <div className="flex flex-col justify-between mt-2">
          <Title>{formatMessage(messages.title)}</Title>
          <Label className="mt-4 mb-4" name={formatMessage(messages.invitationNameInputLabel)}>
            <TextInput
              type="string"
              placeholder={formatMessage(messages.invitationNameInputLabel)}
              name="invitationname"
              value={invitation.invitationname}
              onChange={handleChange}
            />
          </Label>
          <Label name={formatMessage(messages.testLanguageSelectInputLabel)}>
            <Select
              bootstrapped
              name="evaluatedlanguage"
              options={languageOptionsTest}
              onChange={value => setInvitation({ ...invitation, evaluatedlanguage: value })}
              value={invitation.evaluatedlanguage}
              required
              className="mt-2 w-full"
            />
          </Label>

          {askForEvalType && (
            <div className="mt-4">
              <Label name={formatMessage(messages.evaluationTypeSelectInputLabel)}>
                <Select
                  bootstrapped
                  name="evalType"
                  options={evalTypeOptions}
                  onChange={value => setEvaltype(value)}
                  value={evalType}
                  required
                  className="mt-2 w-full"
                />
              </Label>
            </div>
          )}

          <Label className="mt-4 mb-4" name={formatMessage(messages.maxEvaluationsNumberInputLabel)}>
            <TextInput
              type="number"
              placeholder={formatMessage(messages.maxEvaluationsNumberInputLabel)}
              name="maxevaluations"
              value={invitation.maxevaluations}
              onChange={handleChange}
            />
          </Label>
          <Label className="mb-4" name={formatMessage(messages.startTimeLabel)}>
            <DateInput name="startstimestamp" onChange={date => handleDateChange(date, "startstimestamp")} value={invitation.startstimestamp} />
          </Label>
          <Label className="mb-4" name={formatMessage(messages.expirationTimeLabel)}>
            <DateInput name="expirestimestamp" onChange={date => handleDateChange(date, "expirestimestamp")} value={invitation.expirestimestamp} />
          </Label>
          <Button primary className="mt-4" onClick={handleSubmit} loading={loading}>
            {formatMessage(messages.submitButton)}
          </Button>
        </div>
      </div>
    </ModalContainer>
  )
}

export default CreateInvitationModal
