import { useSelector } from "react-redux"
import { Company } from "src/api/types"
import { companySelector } from "src/store/company/selectors"
import { useCompanyUUID } from "./useCompanyUUID"

export const useCurrentCompany = (): Company | undefined => {
  const uuid = useCompanyUUID()
  const { result } = useSelector(companySelector(uuid))
  return result as Company | undefined
}
