import React, { useEffect, useState } from "react"
import ConfirmModal from "../../../base/modals/confirmModal"
import notifications from "../../../../utils/notifications"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../../../utils/hooks/useFormatMessage"
import { Dropdown } from "../../tests/components/Dropdown"
import { ClipboardCopyIcon, PencilAltIcon, TrashIcon } from "@heroicons/react/solid"
import NoticeModal from "../../../../components/base/modals/NoticeModal"
import copy from "copy-to-clipboard"
import useDeleteInvitation from "../../../../utils/hooks/useDeleteInvitation"
import useUser from "../../../../utils/hooks/useUser"
import UpdateInvitationModal from "./UpdateInvitationModal"

const messages = defineMessages({
  sendReminderTooltip: "Last reminder sent on ",
  noReminderSentTooltip: "No reminders sent so far.",
  sendReminderSuccesNotificationTitle: "Reminder send",
  sendReminderSuccesNotificationMessage: "An email was sent to the person related to this test.",
  sendReminderErrorNotificationTitle: "Oops! Something went wrong.",
  deleteInivtationTooltip: "Delete invitation",
  deleteInvitationSuccessNotificationTitle: "You have deleted the invitation",
  deleteInvitationSuccessNotificationMessage: "The URL will not be valid from now on.",
  deleteInvitationErrorNotificationTitle: "Oop! Something went wrong.",
  deleteInvitationModalMessage: "Are you sure you want to delete this invitation?",
  deleteInvitationModalButton: "Delete",
  confirmDeleteMessage: "Are you sure you want to delete this level evaluation?",
  confirmDeleteButton: "DELETE",
  confirmArchiveMessage: "Are you sure you want to archive this level evaluation?",
  confirmArchiveButton: "ARCHIVE",
})

const Action: React.FC<any> = props => {
  const { row } = props

  const token: string = row.token

  const [showEdit, setShowEdit] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)

  const { deleteInvitation } = useDeleteInvitation()

  const [companyUUID, setCompanyUUID] = useState("")

  const user = useUser()
  useEffect(() => {
    if (user) {
      setCompanyUUID(user.company.uuid)
    }
  }, [user])

  const handleDelete = async () => {
    try {
      await deleteInvitation(row.uuid, companyUUID)
      setConfirmDelete(false)
      notifications.success(
        formatMessage(messages.deleteInvitationSuccessNotificationTitle),
        formatMessage(messages.deleteInvitationSuccessNotificationMessage)
      )
    } catch (error) {
      // @ts-ignore
      notifications.error(formatMessage(messages.deleteInvitationErrorNotificationTitle), error.message)
    }
  }

  const formatMessage = useFormatMessage()

  return (
    <>
      <UpdateInvitationModal visible={showEdit} invitation={row} onClose={() => setShowEdit(false)} />
      <ConfirmModal
        visible={confirmDelete}
        title={formatMessage(messages.deleteInvitationModalMessage)}
        confirmText={formatMessage(messages.confirmDeleteButton)}
        onConfirm={handleDelete}
        onClose={() => setConfirmDelete(false)}
      />
      <Dropdown
        options={[
          {
            id: "copy-to-clipboard",
            name: "Copy invitation link",
            Icon: ClipboardCopyIcon,
            onClick: () => {
              const origin = window?.location?.origin ?? document?.location?.origin
              copy(`${origin}/invitation?token=${token}`)
            },
          },
          {
            id: "edit-invitation",
            name: "Edit this invitation",
            Icon: PencilAltIcon,
            onClick: () => {
              setShowEdit(true)
            },
          },
          {
            id: "delete",
            name: "Delete invititation",
            Icon: TrashIcon,
            onClick: () => {
              setConfirmDelete(true)
            },
          },
        ]}
      />
    </>
  )
}

export default Action
